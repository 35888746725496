<template>
  <div>
    <DashboardLayout v-if="$route.meta.authOnly">
      <router-view />
    </DashboardLayout>
    <router-view v-else />
  </div>
</template>

<script>
import DashboardLayout from "./Layout/DashboardLayout.vue";

export default {
  name: "App",
  data() {
    return {
      show_gif: null,
    };
  },

  components: {
    DashboardLayout,
  },


  mounted() {
  },

  methods: {
  }


};
</script>

<style>
body {
  background: var(--main-bg) !important;
  min-height: 100vh;
  /* font-family: 'Montserrat' !important; */
  font-family: 'Reddit Sans', sans-serif !important;
}

@font-face {
  /* font-family: Montserrat; */
  font-family: 'Reddit Sans';
  src: url(./assets/fonts/RedditSans-Regular.ttf);
  font-weight: 400;
  font-display: swap;
}

@font-face {
  /* font-family: Montserrat; */
  font-family: 'Reddit Sans';
  src: url(./assets/fonts/RedditSans-Medium.ttf);
  font-weight: 500;
  font-display: swap;
}

@font-face {
  /* font-family: Montserrat; */
  src: url(./assets/fonts/RedditSans-SemiBold.ttf);
  font-family: 'Reddit Sans';
  font-weight: 600;
  font-display: swap;
}

@font-face {
  /* font-family: Montserrat; */
  src: url(./assets/fonts/RedditSans-ExtraBold.ttf);
  font-family: 'Reddit Sans';
  font-weight: 700;
  font-display: swap;
}

@font-face {
  /* font-family: Montserrat; */
  src: url(./assets/fonts/RedditSans-Bold.ttf);
  font-family: 'Reddit Sans';
  font-weight: 800;
  font-display: swap;
}

@font-face {
  /* font-family: Montserrat; */
  src: url(./assets/fonts/RedditSans-Black.ttf);
  font-family: 'Reddit Sans';
  font-weight: 900;
  font-display: swap;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 800 !important;
}

#preloader {
  background-image: url('assets/images/svglogo2.svg');
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20%;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 100;
}

:root {
  --main-bg: #f0f2ff;
  --white-bg: #ffffff;
  --active-bg2: #090255;
  --active-bg: #090255;
  --side-bg: #090255;
  --white: #ffffff;
  --black: #000000;
  --border: #c9c9f0;
  --breadcrumb-bg-color: #d9d9d9;
  --bg-3: #e0e4fc;
  --grey: #7a7a7a;
  --grey-2: #cccccc;
  --rating: #ffd700;
  --course-price-orange: #ff6b4a;
  --formgradient: linear-gradient(to right top,
      #090255,
      #090255,
      #090255,
      #090255,
      #7014f9);
}

@font-face {
  font-family: Garet;
  src: url(./assets/fonts/Garet-Bold.ttf);
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: Garet;
  src: url(./assets/fonts/Garet-Light.ttf);
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: Coves;
  src: url(./assets/fonts/Coves-Bold.otf);
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: Coves;
  src: url(./assets/fonts/Coves-Light.otf);
  font-weight: 300;
  font-display: swap;
}

.fs-7 {
  font-size: 14px !important;
}

.mb-20 {
  margin-bottom: 20px;
}

.svg-icon {
  fill: var(--white);
}

/*pagination css */
.pagination-card {
  overflow: auto;
}

/* .page-link {
  z-index: 2 !important;
  color: #ffffffcc !important;
  background-color: #0d6efd !important;
  box-shadow: none !important;

  border-color: #0d6efd !important;
  font-size: 15px !important;
 
  font-weight:600;
}

.page-item.active .page-link {
  z-index: 2 !important;
  color: #0d6efd !important;
  box-shadow: none !important;
  background-color: #ffffffcc !important;
  border-color: #ffffffcc !important;
 
}

.pagination_box ul li {
  border: none !important;
  margin-bottom: 20px !important;
  margin-bottom: 0 !important;
  color: white;
}

.page-item:first-child .page-link,
.page-item:last-child .page-link {
  box-shadow: none !important;
} */
.VuePagination__pagination {
  box-shadow: rgb(0 0 0 / 20%) 0px 5px 15px !important;

  gap: 4px;
}

.VuePagination nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.VuePagination nav p {
  font-size: 15px;
  margin-bottom: 0;
}

.VuePagination ul {


  margin-bottom: 0;
}

.VuePagination__pagination {
  box-shadow: none !important;
}



/**================ AUTH PAGE COMMON CSS START ================ **/

.auth-sec {
  min-height: 100vh;
  display: flex;
  align-items: center;
  background: var(--white-bg);
}

.auth-image {
  position: relative;
  background-image: var(--gradient);
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 1;
}

.form-heading h3 {
  font-weight: 700;
  color: var(--black-2);
  /* padding-bottom: 15px; */
  font-size: 30px;
}

.form-heading p {
  line-height: 24px;
  color: var(--grey);
  font-weight: 500;
  font-size: 16px;
}

.form-box {
  width: 100%;
  max-width: 450px;
}

/* .input-group {
  box-shadow: 0px 0px 15px rgb(0 0 0 / 7%);
} */

.label-box label {
  font-size: 14px;
  font-weight: 600;
  color: var(--black-2);
}

.input-group .form-control {
  min-height: 50px;
  border-radius: 4px;
  font-size: 16px;
  border-color: var(--grey-2);
  font-weight: 500;
  background-color: var(--white-bg);
}

.input-group .form-control:focus {
  border-color: var(--grey-2);
}

.input-group .form-control::placeholder {
  font-size: 13px;
  color: var(--grey);
  font-weight: 500;
}

.input-group .input-group-text {
  background-color: var(--white-bg);
  border-color: var(--grey-2);
}

.button-box .btn-primary {
  width: 100%;
  min-height: 50px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  color: var(--white);
  background-image: var(--gradient);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-box .btn-primary:hover {
  background-image: var(--gradient);
}

.button-box .btn-primary:active {
  background-image: var(--gradient);
}

/**================ AUTH PAGE COMMON CSS FINISH ================ **/

/**================ OTP INPUT FIELD CSS START ================ **/

.otp-input div {
  justify-content: space-between;
  width: 100%;
}

input.otp-input {
  min-height: 50px;
  border-radius: 4px;
  font-size: 16px;
  border: 1px solid var(--grey-2);
  font-weight: 500;
  background-color: var(--white-bg);
  /* width: 60px; */
  box-shadow: 0px 0px 15px rgb(0 0 0 / 7%);
  padding: 10px;
}

input.otp-input:focus {
  outline: 1px solid var(--active-bg);
}

/**================ OTP INPUT FIELD CSS FINISH ================ **/

@media all and (min-width: 768px) and (max-width: 991px) {
  .form-heading h3 {
    font-size: 24px;
  }

  .form-heading p br {
    display: none;
  }
}


@media all and (min-width: 320px) and (max-width: 767px) {

  .auth-sec {
    padding: 30px 0;
  }

  .form-heading {
    text-align: center;
  }

  .form-heading h3 {
    font-size: 24px;
  }

  .form-heading p {
    font-size: 14px;
  }

  .form-heading p br {
    display: none;
  }

  body .cardWrapper {
    padding: 10px;
  }
}


.error_msg {
  color: red;
  font-size: 12px;
  font-weight: 500;
}

.neWpagination .page-item .page-link {
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px !important;
  border: 1px solid #5271ff;
  color: #5271ff !important;
  background: #fff !important;
  font-weight: 700;
}

.neWpagination .page-item .page-link.active {
  border: 1px solid #5271ff;
  background: #5271ff !important;
  color: #FFF !important;
}

/* user profile */

.outline-profile {
  outline: 3px solid var(--active-bg);
  outline-offset: 3px;
}


/* form input */

label {
  font-size: 14px;
  font-weight: 600;
  color: var(--black-2);
}

:is(.form-control:focus, .form-select:focus) {
  border-color: #6a64f1 !important;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05) !important;
}

:is(.form-select, .form-control) {
  height: 45px;
}

.form-control:focus {
  border-color: var(--grey-2);
}

.form-control::placeholder {
  font-size: 13px;
  color: var(--grey);
  font-weight: 500;
}


input,
textarea.form-control,
select.form-select {
  font-size: 14px !important;
}


.main-product img {
  height: 84px;
  width: 84px;
  object-fit: cover;
}

.cursor-pointer {
  cursor: pointer;
}

/* search & filter */
.mainsearch input:focus-visible {
  outline: none;
}

.mainsearch {
  border: 1px solid rgb(222, 226, 230);
  box-shadow: 0px 4px 20px 0px rgba(238, 238, 238, 0.50);
  height: 45px;
}

.mainsearch:focus-within {
  border: 1px solid var(--active-bg);
}

.transaction-table .table>:not(caption)>*>* {
  padding: 0.9rem 0.8rem;
}

.payment-detail:hover {
  background: #d6d1d154;
}

.otp-input {
  width: 40px;
  height: 40px;
  padding: 2px;
  margin: 0 5px;
  font-size: 20px;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
}

.btnLogin-main {
  background: var(--active-bg);
  border: 1px solid var(--active-bg);
  transition: all 0.5s ease-in-out;
  height: 50px;
  display: flex;
}

.btnLogin-main:hover {
  background: var(--white);
  color: var(--active-bg) !important;
}

.date__picker .dp__input {
  min-height: 50px !important;
}

.logo-img img {
  max-width: 200px;
  margin: 0 auto 12px;
  padding: 0;
  display: block;
}

body .form-check-input:checked {
  background-color: var(--active-bg);
  border-color: var(--active-bg);
}

input.form-control:focus~.input-group {
  border: 1px solid red;
}

.text-purple {
  color: var(--active-bg) !important;
}

.cardWrapper {
  padding: 26px 28px 29px;
  border-radius: 20px;
  border: 1px solid #F8F9FA;
  box-shadow: 0px 4px 20px 0px rgba(238, 238, 238, 0.50);
}

@media screen and (max-width:767px) {
  .cardWrapper {
    padding: 15px;
  }
}

button.btnCopy {
  background-color: var(--active-bg);
  border: 1px solid var(--active-bg);
}

input[type='number']::-webkit-inner-spin-button {
  appearance: none;
}


@media screen and (max-width:992px) {
  body .chartTitle {
    font-size: 16px !important;
  }

  body .icon-shape {
    height: 50px;
    width: 50px;
  }

  body .icon-shape i {
    font-size: 1.5rem;
  }
}
</style>
