<template>
  <div>
    <div class="inner-wrapper h-100">
      <section class="dashboard-layout">
        <header class="head">
          <div class="navbar">
            <div class="headMaion d-flex align-items-end gap-5">
              <div class="dashboard-logo">
                <a href="#">
                  <img src="../assets/images/logoevolve2.png" alt="">
                </a>
              </div>
              <div class="menu-icon" @click="toggleSidebar">
                <img src="../assets/icon/hamburger.svg" alt="">
              </div>
            </div>

            <div class="user-info d-none d-md-block">
              <ul class="user-info-list">

                <li>
                  <div class="dropdown">
                    <button class="btn btn-secondary shadow-none" type="button" data-bs-toggle="dropdown"
                      aria-expanded="false" id="user-drop">
                      <img v-if="studentImage" :src="studentImage" alt="student_image"
                        accept="image/png, image/gif, image/jpeg, image/avif" class="rounded-circle object-fit-cover"
                        width="50" height="50" />

                      <img v-else src="../assets/icon/defaultUser.svg" alt="student_image" type="file"
                        accept="image/png, image/gif, image/jpeg, image/avif" class="rounded-circle object-fit-cover"
                        width="50" height="50" @change="handleImageUpload" />


                    </button>
                    <ul class="dropdown-menu custom_dropdown" id="user-drop-menu">
                      <li>
                        <router-link class="dropdown-item" to="/setting">
                          <span>
                            <!-- <img src="../assets/icon/user.svg" alt="user icon" class="img-fluid" /> -->
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M16.6673 17.5V15.8333C16.6673 14.9493 16.3161 14.1014 15.691 13.4763C15.0659 12.8512 14.218 12.5 13.334 12.5H6.66732C5.78326 12.5 4.93542 12.8512 4.31029 13.4763C3.68517 14.1014 3.33398 14.9493 3.33398 15.8333V17.5"
                                stroke="#8E9AB2" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                              <path
                                d="M9.99935 9.16667C11.8403 9.16667 13.3327 7.67428 13.3327 5.83333C13.3327 3.99238 11.8403 2.5 9.99935 2.5C8.1584 2.5 6.66602 3.99238 6.66602 5.83333C6.66602 7.67428 8.1584 9.16667 9.99935 9.16667Z"
                                stroke="#8E9AB2" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          </span>
                          <span class="New__text fs-7">{{ user?.name }}</span>
                        </router-link>
                      </li>
                      <li>
                        <router-link class="dropdown-item" to="/setting">
                          <span>
                            <img src="../assets/icon/setting.svg" alt="setting icon" class="img-fluid" />
                          </span>
                          <span class="New__text fs-7">Setting</span>
                        </router-link>
                      </li>
                      <li>
                        <a class="dropdown-item" href="" @click.prevent="logout()">
                          <i class="bi bi-box-arrow-right logout_icon" style="font-size:20px;"></i>
                          <span class="New__text fs-7">Logout</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </header>
        <div class="container-fluid user">
          <div class="row">
            <div class="col-md-3 col-lg-2 px-0 CustomSidebar"
              :class="{ 'Newsidebar': showSidebar, 'collapsed-sidebar': !showSidebar }">
              <div class="sidebar">
                <div class="sidebar-list-box">
                  <ul class="menu-list" id="menu">
                    <li v-for="(sidelink, index) in SideData" :key="index">
                      <router-link :to="sidelink.link" v-if="!sidelink.child" @click="closeSidebarOnMobile">
                        <i class="text-white" :class="sidelink.icon"></i>
                        <p class="mb-0" v-if="showSidebar">{{ sidelink.name }}</p>
                      </router-link>

                      <div v-else>
                        <a :href="'#submenu' + index" data-bs-toggle="collapse" class="nav-link navarrow align-middle"
                          :class="$route.meta.submenu == sidelink.name ? 'active' : ''" @click="showFullSidebar">
                          <i class="text-white" :class="sidelink.icon"></i>
                          <p class="mb-0" v-if="showSidebar">{{ sidelink.name }}</p>
                        </a>
                        <ul class="submenu-box collapse flex-column p-0" :id="'submenu' + index" data-bs-parent="#menu">

                          <li class="w-100" v-for="(subchild, index) in sidelink.child" :key="index">

                            <!-- display item with click functionality -->
                            <router-link v-if="!subchild.lock" :to="subchild.link" class="nav-link"
                              @click="closeSidebarOnMobile">
                              <span v-html="SidebarIcon[subchild.icon]"> </span>
                              <i class="bi" :class="{ 'text-white': true, [subchild.icon]: true }"></i>
                              <p class="mb-0" v-if="showSidebar">{{ subchild.name }}</p>

                            </router-link>

                            <!-- display item without click functionality -->
                            <a v-else class="nav-link">
                              <span v-html="SidebarIcon[subchild.icon]"> </span>
                              <div class="d-flex align-items-center  w-100">
                                <div v-if="subchild.lock" class="menu-text">
                                  <i class="bi" :class="{ 'text-white': true, [subchild.lock]: true }"></i>
                                </div>
                                <p class="mb-0 ms-2" v-if="showSidebar">{{ subchild.name }}</p>

                              </div>
                            </a>



                          </li>



                        </ul>
                      </div>

                    </li>
                    <li>
                      <a class="nav-link" href="" @click.prevent="logout()">
                        <i class="bi bi-box-arrow-right text-white" style="font-size:20px;"></i>
                        <p class="m-0" v-if="showSidebar">Logout</p>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="col-md-9 col-lg-10 px-0"
              :class="{ 'col-lg-10': showSidebar, 'col-lg-10 mx-auto': !showSidebar, 'px-0': showSidebar }">
              <div class="main-content">
                <div class="content-box">
                  <slot></slot>
                </div>
              </div>
            </div>
            <div class="sidebar-overlay" :class="{ 'show': showSidebar }" @click="showSidebar = false">
            </div>
          </div>
        </div>

      </section>
    </div>
  </div>
</template>

<script>

import ApiClass from '@/api/api';
import SidebarIcon from "../assets/js/Icon.js";
import SideDataJson from "../assets/json/Sidebar.json";

export default {
  name: 'NavbarComponent',
  components: {
    // NewSidebarIcon,
  },
  data() {
    return {
      user: {},
      showSidebar: true,
      activeId: 0,
      SidebarIcon,
      SideData: SideDataJson.SideData,
      studentImage: ''
    };
  },
  async mounted() {
    if (window.innerWidth <= 767) {
      this.showSidebar = false;
    }
    var resp = await ApiClass.getRequest("/profile/get", true);
    if (resp.data.status_code == "1") {
      this.studentImage = resp?.data?.data?.profile_image ?? this.studentImage;
    }
    this.user = this.$store.getters.getUser;

    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;


      if (this.isMobile) {
        this.updateBodyOverflow(true);
      }
    })
    this.updateBodyOverflow(this.showSidebar);

  },
  computed: {
    isMobile() {
      return this.windowWidth <= 767
    },
  },
  watch: {
    showSidebar(newValue) {
      this.updateBodyOverflow(newValue);
    }
  },

  methods: {
    logout() {
      this.$swal({
        title: "LogOut",
        text: "Are you sure you want to logout?",
        icon: "info",
        iconColor: "#0d6efd",
        showCancelButton: true,
        confirmButtonColor: "#0d6efd",
        cancelButtonColor: "#d33",
        confirmButtonText: "logout"
      }).then(async (result) => {
        if (result.isConfirmed) {

          this.loading = true;
          let response = await ApiClass.deleteRequest('logout', true);

          if (response?.data) {
            this.loading = false;
            if (response.data.status_code == 0) {
              this.failed(response.data.message)
              return
            }
            if (response.data.status_code == 1) {
              this.success(response.data.message)
              localStorage.removeItem("token");
              localStorage.removeItem("user");
              this.$store.commit("CLEAR_STORE", "");
              return this.$router.push("/");
            }
          }
          this.$swal({
            title: "Logout!",
            text: "You are now signed out!!",
            icon: "success"
          })
        }
      });
    },
    toggleSidebar() {
      this.showSidebar = !this.showSidebar;
    },
    updateBodyOverflow(showSidebar) {
      if (showSidebar && window.innerWidth <= 767) {
        // document.body.classList.add('overflow-hidden');
      } else {
        document.body.classList.remove('overflow-hidden');
      }
    },
    selectMember: function (id) {
      this.activeId = id;
    },

    closeSidebarOnMobile() {
      if (window.innerWidth <= 767) {
        this.showSidebar = false;
      }
    },
    showFullSidebar() {
      this.showSidebar = true;
    },
  },


};
</script>

<style scoped>
/**===================== HEADER CSS START =====================**/
header.head {
  background: var(--white);
  width: 100%;
  padding: 7px 20px;
  padding-right: 35px;
  z-index: 155;
  position: relative;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.dashboard-logo img {
  max-width: 175px;
}

.user-info ul.user-info-list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 20px;
}

.user-info ul.user-info-list li .user-box .dropdown button#notification {
  background: transparent;
  border: none;
}

ul.user-info-list li .dropdown button#user-drop {
  background: transparent;
  border: none;
  padding: 0;
}

ul.user-info-list li .dropdown button#user-drop img {
  width: 50px;
  border-radius: 40px;
}

ul#user-drop-menu {
  left: -149px;
  padding: 10px 0;
  margin: 0;
  border: 1px solid #F8F9FA;
  box-shadow: 0px 4px 20px 0px rgb(53 83 218 / 21%);
  left: -150px;
}

ul#user-drop-menu li a {
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 11px;
  font-size: 14px;
}

ul#user-drop-menu li a span img {
  max-width: 20px;
}

.submenu-box li a.nav-link p {
  font-size: 12px !important;
}

.sidebar {
  height: 100%;
  background: var(--side-bg);
  border-radius: 0;

}

.logo-box {
  padding: 14px;
  margin-bottom: 40px;
  text-align: center;
}

ul.menu-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

ul.menu-list li a {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 15px 10px 15px 25px;
  text-align: center;
  text-decoration: none;
}

ul.menu-list li a p {
  color: var(--white);
  font-weight: 600;
  font-size: 13px;
}

ul.menu-list li a span {
  display: inline-block;
}

ul.menu-list li a span svg {
  fill: var(--white);
}

.router-link-active i {
  color: var(--active-bg) !important;
}

:is(.menu-list li .router-link-active, .menu-list li .router-link-active:hover) {
  background: #f8f9fa;
  position: relative;
}

ul.menu-list li .router-link-active p {
  color: var(--active-bg);
  font-weight: 600;
}

ul.menu-list li a:hover {
  background: #3a58dd;
}

ul#notfication-drop {
  left: -240px;
  min-width: 350px;
  padding: 10px 10px;
}

.notifaction-box p {
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
}

.submenu-box {
  background: #5a71ec80;
  list-style: none;
}


.navarrow {
  position: relative;
}

.navarrow::after {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  background-image: url("../assets/icon/arrow.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  right: 6px;
  top: 13px;
  transform: rotate(0deg);
  transition: all 0.3s ease-in-out;
}

.navarrow[aria-expanded="true"]::after {
  transform: rotate(90deg);
  transition: all 0.3s ease-in-out;
}

.submenu-box li {
  margin-bottom: 0 !important;
}

.content-box {
  padding: 20px 8px 0;
}

.menu-icon {
  cursor: pointer;
}

.CustomSidebar.collapsed-sidebar {
  /* display: none; */
  width: fit-content !important;
}

:is(.New__text, .logout_icon) {
  color: #7a8599;
}

.custom_dropdown .dropdown-item.active,
.custom_dropdown .dropdown-item:active,
.custom_dropdown .dropdown-item:active svg,
.custom_dropdown .dropdown-item:active i,
.custom_dropdown .dropdown-item:active span {
  background-color: var(--active-bg);
  color: #FFF;
  fill: #FFF;
  stroke: #FFF !important;
}

.default-image {
  background: var(--active-bg);
}


@media screen and (max-width:767px) {
  .CustomSidebar {
    position: fixed;
    top: 74px;
    width: 300px;
    height: 100%;
    z-index: 99;
  }

  header.head[data-v-bfccaedc] {
    padding: 5px;
  }

  .headMaion {
    width: 100%;
    justify-content: space-between;
  }
  .CustomSidebar.collapsed-sidebar {
  display: none !important;
}
}

@media (max-width: 768px) {
  .sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
    display: none;
    transition: opacity 0.3s ease;
  }

  .sidebar-overlay.show {
    display: block;
    opacity: 1;
  }

  .CustomSidebar {
    z-index: 20;
  }

  .sidebar {
    overflow: scroll;
    padding-bottom: 7rem;
  }

}
</style>
