<template>
  <div>
    <section class="login-sec auth-sec user-panel">
      <div class="container-fluid">
        <div class="row align-items-center justify-content-between">
          <div class="col-md-12 col-lg-12 mobile-form">
            <div class="form-login">
              <div class="logo-img text-center">
                <img src="../../assets/images/logoevolve2.png" alt="evolve" class="img-fluid">
              </div>

              <div class="form-heading mb-3">
                <h3 class="mb-2 fw-bold">Hello ! Welcome</h3>
                <p class="mb-0 fs-7">
                  Login with your data that you entered during
                  your registration
                </p>
              </div>

              <div class="form-box">
                <form class="row form-row">
                  <div class="col-md-12 col-lg-12 col-xl-12 mb-4">
                    <div class="label-box mb-1">
                      <label>Email Address</label>
                    </div>
                    <div class="input-group">
                      <input type="text" class="form-control shadow-none" placeholder="example@gmail.com"
                        aria-label="email" aria-describedby="basic-addon1" v-model="email" />
                    </div>
                    <span class="error_msg" v-if="v$.email.$error">
                      {{ v$.email.$errors[0].$message }}
                    </span>
                  </div>
                  <!--col-md-12 col-lg-12 col-xl-12-->

                  <div class="col-md-12 col-lg-12 col-xl-12 mb-4">
                    <div class="label-box mb-1">
                      <label>Password</label>
                    </div>
                    <div class="input-group position-relative">
                      <input :type="hidden ? 'password' : 'text'" class="form-control shadow-none rounded-1"
                        placeholder="Enter Password" aria-label="password" aria-describedby="basic-addon1"
                        v-model="password" />

                      <span class="passw-eye bg-transparent position-absolute">
                        <img :src="hidden ? hide : show" alt="" class="img-fluid" style="cursor: pointer"
                          @click="hidden = !hidden" />
                      </span>
                    </div>

                    <span class="error_msg" v-if="v$.password.$error">
                      {{ v$.password.$errors[0].$message }}
                    </span>
                    <div class="forgot-link">
                      <router-link to="/forgotpassword" class="text-decoration-none">
                        Forgot Password
                      </router-link>
                    </div>
                    <!--forgot-link-->
                  </div>
                  <div class="col-md-12 col-lg-12 col-xl-12 mb-4">
                    <input type="checkbox" value="lsRememberMe" id="rememberMe"> <label for="rememberMe">Remember
                      me</label>

                  </div>

                  <div class="col-md-12 col-lg-12 col-xl-12 mb-2">
                    <div class="button-box">
                      <button class="btnLogin-main w-100 text-white rounded align-items-center justify-content-center"
                        type="submit" v-show="!loader" @click.prevent="login">
                        Sign In
                      </button>
                      <button v-show="loader" class="btnLogin-main w-100 rounded align-items-center justify-content-center"
                        type="button" >
                        <div>
                          <AppLoader></AppLoader>
                        </div>

                      </button>
                    </div>
                  </div>

                  <div class="col-md-12 col-lg-12 col-xl-12">
                    <div class="signup-box">
                      <p class="m-0">
                        Don't have an account?
                        <span>
                          <router-link to="/register" class="text-decoration-none">
                            Sign Up
                          </router-link>

                        </span>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import hide from "@/assets/icon/hide.png";
import show from "@/assets/icon/show.png";
import { required, helpers } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import ApiClass from "../../api/api.js";
import AppLoader from '@/shared/AppLoader.vue';

export default {
  name: "LoginView",
  components: {
    AppLoader,
  },
  data() {
    return {
      loader: false,
      hidden: true,
      hide: hide,
      show: show,
      email: "",
      password: "",
    };
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  mounted() {
    this.$store.commit("SET_R_KEY", "");
    this.$store.commit("SET_VM", "");
    this.$store.commit("SET_USER_EMAIL", "");
    this.$store.commit("SET_USER_MOBILE", "");
    this.$store.commit("SET_USER_UNIQUE_ID", "");
    this.$store.commit("SET_LOGIN_TYPE", "");
    this.$store.commit("SET_WALLET_ADDRESS", "");
    this.$store.commit("SET_USER", {});
  },
  validations() {
    return {
      email: {
        required: helpers.withMessage("Login Id is Required", required),
      },
      password: {
        required: helpers.withMessage("Password is Required", required),
      },
    };
  },
  methods: {
    async login() {
      const result = await this.v$.$validate();
      if (!result) {
        this.loader = false;
        return;
      }
      if (this.v$.$invalid) {
        this.loader = false;
        return;
      }
      this.loader = true;
      let body = {
        email: this.email.trim(),
        password: this.password,
      };
      let response = await ApiClass.postRequest("login", false, body);
      if (response?.data?.status_code == 0) {
        this.loader = false;
        this.failed(response?.data?.message);
        return;
      }
      this.success(response?.data?.message);
      localStorage.setItem("modal", 'Show_Modal');
      localStorage.setItem("token", response.data.data.token);
      localStorage.setItem("user", JSON.stringify(response.data.data.user));
      this.$store.commit("SET_USER", response.data.data.user);
      this.$router.push("/upcoming-webinars");
      this.loader = false;
      return;
    }
  }
}
</script>

<style scoped>
.auth-image img {
  max-width: 450px;
}

.forgot-link {
  text-align: end;
  margin-top: 5px;
}

.forgot-link a {
  font-weight: 600;
  font-size: 14px;
  color: var(--active-bg);
}


.signup-box p {
  line-height: 24px;
  color: var(--grey);
  font-weight: 500;
  font-size: 14px;
  text-align: center;
}

.signup-box p a {
  color: var(--active-bg);
  font-weight: 600;
}


.form_wrapper {
  background: #f7f7f7;
}

.form-login {
  max-width: 450px;
  margin: 0 auto;
  background: #FFF;
  padding: 20px;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.auth-image {
  position: relative;
  background: var(--formgradient);
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 1;
}


.form-heading p {
  line-height: 24px;
  color: var(--grey);
  font-weight: 500;
  font-size: 16px;
}

.form-box {
  width: 100%;
  max-width: 450px;
}

.login-sec .logo-img img {
  max-width: 200px;
  margin: 0 auto 12px;
  padding: 0;
  display: block;
}


@media (min-width:768px) and (max-width: 992px) {
  .form-login {
    max-width: unset;
  }
}

@media screen and (max-width: 992px) {
  .form-box {
    margin: auto;
  }

  .mobile-form {
    /* height: 100vh; */
    display: flex;
    justify-content: center;
    /* align-items: center; */
  }

  .form_wrapper {
    justify-content: center !important;
  }

  .form-heading p {
    font-size: 14px;
  }
}

@media screen and (max-width: 1024px) {
  .auth-image img {
    max-width: 350px;
  }
}

.passw-eye {
  top: 12px;
  right: 12px;
  z-index: 999;
}
</style>
