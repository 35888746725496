import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import Pagination from 'v-pagination-3';
import Votpinput from "vue3-otp-input";


import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'


import { onHandleKeyPress, onHandlePaste, onHandleKeyUp, onHandleKeyDown } from './api/inputText.js';

const mixins = {
    methods: {
        handleDragOver: function (event) {
            event.preventDefault();
            // Handle the drag over event here
        },
        onHandleKeyPress,
        onHandlePaste,
        onHandleKeyUp,
        onHandleKeyDown,

        success: function (message) {
            this.$swal({
                title: message,
                position: "top-end",
                icon: "success",
                toast: true,
                timer: "3000",
                showConfirmButton: false,
            });
        },
        failed: function (message) {
            this.$swal({
                title: message,
                position: "top-end",
                icon: "error",
                toast: true,
                timer: "3000",
                showConfirmButton: false,
            });
        },
        warning: function (message) {
            this.$swal({
                title: message,
                position: "top-end",
                icon: "info",
                toast: true,
                timer: "3000",
                showConfirmButton: false,
            });
        },
        sleep: async function (ms) {
            return await new Promise(r => {
                setTimeout(r, ms)
            })
        },
        formatDate: function (date) {
            var u = new Date(date);
            const dd = String(u.getDate()).padStart(2, '0');
            const mm = String(u.getMonth() + 1).padStart(2, '0');
            const yyyy = u.getFullYear();
            return (
                `${yyyy}-${mm}-${dd}`
            );
        },
        titleCase: function (str) {
            if (str.includes("_")) {
              str = str.replace(/_/g, " ");
            }
            return str.replace(/\b\w/g, function (char) {
              return char.toUpperCase();
            });
          },
    

    }
}

createApp(App).component('v-otp-input', Votpinput).component('VueDatePicker', VueDatePicker).mixin(mixins).use(store).use(router).use(VueSweetalert2).component('pagination', Pagination).mount('#app')
