import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '../views/auth/LoginView.vue'

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView,
    meta: { authOnly: false }
  },

  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '../views/auth/RegisterView.vue'),
    meta: { authOnly: false }
  },

  {
    path: '/otp',
    name: 'otp',
    component: () => import(/* webpackChunkName: "otp" */ '../views/auth/OTPVerification.vue'),
    meta: { authOnly: false }
  },


  {
    path: '/forgotpassword',
    name: 'forgotpassword',
    component: () => import(/* webpackChunkName: "forgotpassword" */ '../views/auth/ForgotPassword.vue'),
    meta: { authOnly: false }
  },


  {
    path: '/resetpassword',
    name: 'resetpassword',
    component: () => import(/* webpackChunkName: "resetpassword" */ '../views/auth/ResetPassword.vue'),
    meta: { authOnly: false }
  },



  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/DashboardView.vue'),
    meta: { authOnly: true }
  },

  {
    path: '/courses',
    name: 'courses',
    component: () => import(/* webpackChunkName: "courses" */ '../views/CourseView.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/my-courses',
    name: 'mycourses',
    component: () => import(/* webpackChunkName: "courses" */ '../views/MyCourseView.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/my-webinars',
    name: 'mywebinars',
    component: () => import(/* webpackChunkName: "courses" */ '../views/MyWebinarView.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/completed-courses',
    name: 'CompletedCourseView',
    component: () => import(/* webpackChunkName: "courses" */ '../views/CompletedCourseView.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "courses" */ '../views/ProfileView.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/affiliate',
    name: 'affiliate',
    component: () => import(/* webpackChunkName: "courses" */ '../views/AffiliateView.vue'),
    meta: { authOnly: true }
  },
  
  {
    path: '/kyc',
    name: 'kyc',
    component: () => import(/* webpackChunkName: "courses" */ '../views/KycView.vue'),
    meta: { authOnly: true }
  },

  {
    path: '/wallet',
    name: 'wallet',
    component: () => import(/* webpackChunkName: "courses" */ '../views/WalletView.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/setting',
    name: 'setting',
    component: () => import(/* webpackChunkName: "courses" */ '../views/SettingView.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import(/* webpackChunkName: "courses" */ '../views/FaqView.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/support',
    name: 'support',
    component: () => import(/* webpackChunkName: "courses" */ '../views/SupportView.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/my-purchase',
    name: 'my-purchase',
    component: () => import(/* webpackChunkName: "courses" */ '../views/MyPurchaseView.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/my-purchase-webinar',
    name: 'my-purchase-webinar',
    component: () => import(/* webpackChunkName: "courses" */ '../views/MyPurchaseWebinarView.vue'),
    meta: { authOnly: true }
  },
  // {
  //   path: '/my-learning',
  //   name: 'mylearning',
  //   component: () => import(/* webpackChunkName: "courses" */ '../views/@MyLearningView.vue'),
  //   meta: { authOnly: true }
  // },
  {
    path: '/pricing/:id',
    name: 'pricing',
    component: () => import(/* webpackChunkName: "courses" */ '../views/pricingView.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/my-packages/:id',
    name: 'my-packages',
    component: () => import(/* webpackChunkName: "courses" */ '../views/AddPackages.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/my-webinar-packages/:id',
    name: 'my-webinar-packages',
    component: () => import(/* webpackChunkName: "courses" */ '../views/AddWebinarPackages.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/feedback',
    name: 'feedback',
    component: () => import(/* webpackChunkName: "courses" */ '../views/SubmitFeedback.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/my-feedback',
    name: 'my-feedback',
    component: () => import(/* webpackChunkName: "courses" */ '../views/MyFeedbackView.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/ticket',
    name: 'ticket',
    component: () => import(/* webpackChunkName: "courses" */ '../views/TicketRaise.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/cart/:uuid',
    name: 'cart',
    component: () => import(/* webpackChunkName: "courses" */ '../views/cartView.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/single-course/:uuid',
    name: 'singlecourse',
    component: () => import(/* webpackChunkName: "courses1" */  '../views/SingleCourse.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/view-single-course/:uuid',
    name: 'viewsinglecourse',
    component: () => import(/* webpackChunkName: "courses1" */  '../views/viewSingleCourse.vue'),
    meta: { authOnly: true }
  },
 
  {
    path: '/purchase/:type/:plan',
    name: 'PurchaseView',
    component: () => import(/* webpackChunkName: "courses" */ '../views/PurchaseView.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/purchase-webinar/:type/:plan',
    name: 'PurchaseWebinarView',
    component: () => import(/* webpackChunkName: "courses" */ '../views/PurchaseWebinarView.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/add-ons/:type/:plan',
    name: 'AddonsView',
    component: () => import(/* webpackChunkName: "courses" */ '../views/AddonsView.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/withdraw/:id',
    name: 'WithdrawList',
    component: () => import(/* webpackChunkName: "WithdrawList" */ '../views/Withdraw/WithdrawList.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/withdraw-details/:id',
    name: 'WithdrwDetails',
    component: () => import(/* webpackChunkName: "WithdrwDetails" */ '../views/Withdraw/WithdrawView.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/withdraw/add',
    name: 'AddWithdraw',
    component: () => import(/* webpackChunkName: "AddWithdraw" */ '../views/Withdraw/AddWithdraw.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/quiz-questions/:uuid/:id',
    name: 'QuizQuestion',
    component: () => import(/* webpackChunkName: "QuizQuestion" */ '../views/QuizQuestionsView.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/my-certificates',
    name: 'my-certificates',
    component: () => import(/* webpackChunkName: "certificates" */ '../views/MyCertificatesView.vue'),
    meta: { authOnly: true }
  },

  {
    path: '/my-webinar-certificates',
    name: 'my-webinar-certificates',
    component: () => import(/* webpackChunkName: "certificates" */ '../views/MyWebinarCertificatesView.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/verify-certificate',
    name: 'verify-certificate',
    component: () => import(/* webpackChunkName: "verify-certificate" */ '../views/VerifyCertificateView.vue'),
    meta: { authOnly: true }
  },

  {
    path: '/webinar-view',
    name: 'webinar-view',
    component: () => import(/* webpackChunkName: "webinar-view" */ '../views/WebinarView.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/coming-soon',
    name: 'coming-soon',
    component: () => import(/* webpackChunkName: "coming-soon" */ '../views/ComingSoonView.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/upcoming-webinars',
    name: 'upcoming-webinars',
    component: () => import(/* webpackChunkName: "upcoming-webinars" */ '../views/UpcomingWebinarsView.vue'),
    meta: { authOnly: true }
  },

  {
    path: '/past-webinars',
    name: 'past-webinars',
    component: () => import(/* webpackChunkName: "past-webinars" */ '../views/PastWebinarsView.vue'),
    meta: { authOnly: true }
  },

  {
    path: '/live-webinars',
    name: 'live-webinars',
    component: () => import(/* webpackChunkName: "live-webinars" */ '../views/LiveWebinarsView.vue'),
    meta: { authOnly: true }
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});


router.beforeEach((to, from, next) => {
 
  const loggedIn = localStorage.getItem("token");
  const isAuth = to.matched.some((record) => record.meta.authOnly); 
  
  if (isAuth && !loggedIn) {
    return next({ path: "/" });
  } else if (!isAuth && loggedIn) {
    return next({ path: "/dashboard" });
  } else if (!isAuth && !loggedIn) {
    return next();
  }
  next();
}); 

export default router;
